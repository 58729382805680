import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["table", "search",
    "mapping_dropdown", "mapping_checkbox", "mapping_fields",
    "sidebar_table", "sidebar_search", "sidebar_sort", "sidebar_current_sort", "modal_currency_field"]

  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }

    // Sidebar table
    if (this.hasSidebar_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createSidebarTable(content_url);
    }
  }

  //
  // Actions
  //
  updateCurrencyFields(event) {
    const currency = event.currentTarget.getAttribute("value");
    console.log(currency)
    this.modal_currency_fieldTargets.forEach(el => {
      el.innerHTML = currency;
    });
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  filterTable() {
    this.setFilterToTable();
  }

  setSidebarFilter() {
    this.setSidebarFilterToTable();
  }

  //
  // Main table filters
  setFilterToTable() {
    let filters = this.getPageFilters();

    if (filters.length) {
      let table = this.tableTarget.tabulator;
      table.setFilter(filters);

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  // Sidebar Table Filters
  setSidebarFilterToTable() {
    let filters = this.getSidebarPageFilters();

    if (filters.length) {
      let sidebar_table = this.sidebar_tableTarget.tabulator;
      sidebar_table.setFilter(filters);
      // close drop down
      if (this.hasSidebar_filterTarget) {
        this.sidebar_filterTarget.classList.remove('show');
      }

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    return filters;
  }

  getSidebarPageFilters() {
    let filters = []

    if (this.hasSidebar_searchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.sidebar_searchTarget.value
      })
    }

    return filters;
  }

  // Sidebar Sort
  sidebarSort(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let sidebar_table = this.sidebar_tableTarget.tabulator;

    // change sort dir
    if (sort_dir == "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    // set sort to table
    sidebar_table.setSort(sort_column, sort_dir);

    // change links
    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
      // if (sort_target.querySelector("i")) sort_target.querySelector("i").remove();
    });
    sort_target.classList.add("active");
    // set new icon
    const new_html = this.getSidebarSortHtml(sort_target.dataset.sortColumnName, sort_dir);
    sort_target.innerHTML = new_html;
    if (this.hasSidebar_current_sortTarget) this.sidebar_current_sortTarget.innerHTML = new_html;
  }

  // gen icon html
  getSidebarSortHtml(column_name, sort_dir) {
    let up_or_down = "up";
    if (sort_dir == "asc") up_or_down = "down"
    const html = column_name + "&nbsp; <i class='fa-sort-alpha-" + up_or_down + " fas sort_dir'></i>"
    return html;
  }


  //
  // Forms
  //

  openModalHotel(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  openModalFood(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openModalEntryTickets(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openModalVisa(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openModalTrainTickets(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openModalAirTickets(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openModalGuide(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  openModalForeignSupplier(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  openModalExtraSupplier(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openModalTransport(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  //
  // Show Supplier
  //
  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }



  //
  // Tables
  //

  // create sidebar
  async createSidebarTable(url) {
    const columns = this.data.get("table-columns");
    const per_page = this.data.get("per-page");
    const persistenceID = this.data.get("persistence-id");
    const table_target = this.sidebar_tableTarget;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: persistenceID,
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getSidebarPageFilters(),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;
    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const table_id = this.data.get("table-id");

    let table_props = {
      index: "id",
      height: (height - 150) + "px",
      persistenceID: "suppliers_table" + table_id,
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getPageFilters(),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  convertToUrlParams(filters) {
    let query_filters = {}
    filters.forEach(el => {
      if (el["value"].length)
        query_filters[el["field"]] = el["value"];
    })

    return new URLSearchParams(query_filters);
  }
}